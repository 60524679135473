import Job from "custom/companion/job"
import MowerMarker from "custom/companion/mower_marker"
import UserMarker from "custom/companion/user_marker"
import "custom/companion/stripe_angle_overlay"

/*
 * Logic for rendering the Google Map within the companion app.
 *
 * This handles rendering the GPS coords of the mower as a marker and
 * the map boundary generated by the mower of its mowing plan as a polygon.
 *
 * Events:
 *
 * "gmap-script-ready" fn() - triggered when the Google Maps JS API is available.
 * "map-updated" fn(map) - triggered when the map has been re-cenetered.
 * "map-rendered" fn(map) - triggered when the map has been initially rendered.
 */

// If the mower is <= this distance (in meters) from the map, both the mower marker
// and map boundary are centered in the map. Otherwise, just the mower. See +center()+.
const JOB_RENDERING_THRESHOLD_IN_METERS = 400

// The Google Map zoom setting to use when just displaying the mower.
const ZOOM_LEVEL = 18

export default class {
  /*
   * Initializes a Google Map by installing the Maps JS library and firing
   * events to render the map, status marker, and mower map.
   */
  constructor(google_api_key, statusLatLng, job, iconImagePath, userIconImagePath, headingDegFromNorth, platform) {
    this.google_api_key = google_api_key
    // The new google.maps.Map object
    this.gmap = null
    // Initialize a +LocationMarker+ w/the provided mower lat/lng coords and icon image.
    this.status = new MowerMarker(statusLatLng, iconImagePath, headingDegFromNorth, platform)
    // Initialize a +LocationMarker+ w/the provided user lat/lng coords and icon image.
    this.userMarker = new UserMarker(null, userIconImagePath)
    // Initialize a +Job+ w/the provided +job+ attributes.
    this.currentJob = new Job(job)
    this.iconImagePath = iconImagePath
    // The map container. Google Map is drawn within this.
    // The companion class also references this.
    this.$map = $("#map")

    var _this = this;

    this.init()

    // Triggered when Google Maps script is loaded.
    $(window).on("gmap-script-ready", (event) => {
      _this.addPolygonGetBoundsFunction()
      _this.render()
      companion.showMowingProgress()
    })
    // Triggered when a new status location is recevied.
    $(window).on("new-mower-status", (event, latLng) => {
      _this.status.update(latLng)
      companion.setVersionUpdateDescription()
      companion.showMowingProgress()
      $(window).trigger("map-updated", _this)
      // The slide event listener for the jobs carousel is removed when a status is posted for an unknown reason.
      // This adds the listener again, after a 100ms timeout to account for unknown race condition. Need to figure out the cause.
      setTimeout(() => {
        previousJobs.addSlideListener('recent')
        previousJobs.addSlideListener('favorite')
      }, 100)
    })
    // Triggered when the users location is obtained.
    $(window).on("new-user-location", (latLng) => { _this.userMarker.update(latLng) })

    // Triggered when the user clicks the `show-mower` button.
    $("[id^=show-mower]").on("click", () => { _this.center() })
  }

  /*
   * Sets up the Google Maps API javascript tag and an event handler that is triggered to setup the map centered
   * on the status location.
   *
   * Fires a "gmap-script-ready" event.
   */
  init() {
    var script = document.createElement('script');
    // Advanced marker requires the `marker` library and beta version
    script.src = `https://maps.googleapis.com/maps/api/js?key=${this.google_api_key}&callback=initMap&libraries=geometry,marker&v=beta`;
    script.async = true;

    // Can't seem to find a GMaps event that is fired when the maps js is loaded. Manually firing an event instead.
    window.initMap = function () {
      $(window).trigger("gmap-script-ready")
    }

    document.head.appendChild(script);
  }

  /*
   * Returns a +google.maps.LatLngBounds+ object containing the bounds
   * of the status and current job.
   */
  getBounds() {
    var bounds = this.currentJob.getBounds()
    bounds.extend(this.status.gLatLng)
    return bounds
  }

  /*
   * Returns the distance (in meters) between the status and centroid of the
   * job polygon.
   *
   * If the status or job is null, Infinity is returned.
   */
  distanceBetween() {
    var res = Infinity
    if (this.currentJob.jobId && !this.status.isEmpty()) {
      res = google.maps.geometry.spherical.computeDistanceBetween(
        this.status.gLatLng,
        this.currentJob.centroid()
      )
    }
    return res
  }

  /*
   * Returns +true+ if the centroid of the job polygon is near the status gps coords.
   */
  isNear() {
    return this.distanceBetween() <= JOB_RENDERING_THRESHOLD_IN_METERS
  }

  /*
   * Centers the Google map on the job + status (if they are near each other) or just the status
   * if there isn't a mower map or the mower map isn't near the status gps coords.
   */
  center() {
    // Triggered prior to centering as listeners may toggle visibility on the map and Google has some issues
    // with hidden elements.
    // Google doesn't like display: none.
    // See https://developers.google.com/maps/documentation/javascript/reference/map?hl=en#Map.fitBounds

    // Only center if not on the job edit page.
    if (!previousJobs.jobToEdit) {
      $(window).trigger("map-updated", this)
      if (this.isNear()) {
        console.debug(`Centering on mower and job. Distance between=${this.distanceBetween()}m.`)
        this.gmap.fitBounds(this.getBounds())
      } else if (this.status.isEmpty()) {
        // Centering w/an empty status triggers a 404 when Google fetches tiles. The tiles then remain blank.
        console.debug("Not centering. Mower status is empty.")
      } else {
        console.debug(`Centering on mower. Distance between=${this.distanceBetween()}m is > ${JOB_RENDERING_THRESHOLD_IN_METERS}m`)
        this.gmap.setZoom(ZOOM_LEVEL)
        this.gmap.panTo(this.status.gLatLng)
      }
    }
  }

  /*
   * Adds a +getBounds()+ method to the google.maps.Polygon class.
   */
  addPolygonGetBoundsFunction() {
    console.debug("Adding function=google.maps.Polygon.prototype.getBounds")
    // https://stackoverflow.com/questions/2177055/how-do-i-get-google-maps-to-show-a-whole-polygon
    google.maps.Polygon.prototype.getBounds = function () {
      var bounds = new google.maps.LatLngBounds();
      var paths = this.getPaths();
      var path;
      for (var i = 0; i < paths.getLength(); i++) {
        path = paths.getAt(i);
        for (var ii = 0; ii < path.getLength(); ii++) {
          bounds.extend(path.getAt(ii));
        }
      }
      return bounds;
    }
  }

  /*
   * Renders the Google Map inside the +$map+ container element.
   *
   * Fires a "map-rendered" event w/this object when rendered.
   */
  render() {
    this.gmap = new google.maps.Map(this.$map.get(0), {
      zoom: 18,
      disableDefaultUI: true,
      mapTypeId: 'hybrid',
      // `mapId` is required to use `AdvancedMarkerElement` markers. This ID was generated in the Google developer console
      mapId: '6a60eed58181c6ca'
    });

    console.debug("Rendered Google Map=", this.gmap)
    var _this = this
    $(window).trigger("map-rendered", _this)
  }

  // Ensures displayed job attributes are in sync with the job record in the database
  updateCurrentJob(jobInfo) {
    if (typeof (jobInfo) == 'string') { jobInfo = previousJobs.jobFromJobId(jobInfo)?.job }
    if (jobInfo) {
      this.currentJob?.hide()
      this.currentJob = new Job(jobInfo, this.gmap)
      if (companion.processedJob?.jobId != jobInfo.jobId) { this.currentJob.show() }
      this.center()
      this.currentJob.checkIfSynced()
      if (this.currentJob.isRecordAndRepeat) {
        companion.removePlan()
      } else {
        $(window).trigger('show-stripe-overlay')
      }
    }
  }
}
